import styled from 'styled-components';

//styles
export const AppFrame = styled.section`
    position:relative;
    height:100vh; width:100vw;
`;

// export const AppHeader = styled.header`
//     background-color: #222;
//     height: 150px;
//     padding: 20px;
//     color: white;
//     transition: all 0.3s ease-in-out;
//     &:hover {
//       color:red;
//       transition: all 0.3s ease-in-out;
//     }
// `;

// export const AppTitle = styled.h1`
//     font-size: 1.5em;
//     animation: AppLogoSpin infinite 20s linear;
// `;

// export const AppIntro = styled.p`
//     font-size: 1.5em;
// `;


// export const AppLogoSpin = keyframes`
//   from { transform: rotate(0deg); }
//   to { transform: rotate(360deg); }
// `;


// export const AppLogo = styled.span`
//   animation: ${AppLogoSpin} infinite 20s linear;
//   height: 80px; width:80px;
//   margin:0px auto;
//   display:block;
// `;