import React, { Component } from 'react';
import styled from 'styled-components';

export const Icon = styled.svg`
  height: 20px;
  fill: #1b516a;
  transition: all 0.2s ease-in-out;
  margin:0px auto;
  display:block;
  &:hover{
    fill:#CFE6CE;
    transition: all 0.2s ease-in-out;
  }
`;

class WeatherIcon extends Component {

  render() {
    return (
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M295.973 160H180.572L215.19 30.184C219.25 14.956 207.756 0 192 0H56C43.971 0 33.8 8.905 32.211 20.828l-31.996 240C-1.704 275.217 9.504 288 24.004 288h118.701L96.646 482.466C93.05 497.649 104.659 512 119.992 512c8.35 0 16.376-4.374 20.778-11.978l175.973-303.997c9.244-15.967-2.288-36.025-20.77-36.025z"/></Icon>
    );
  }
}


export default WeatherIcon;