import React, { Component } from 'react';

//style containers
import { Header, NavItem, NavSettings } from './style.js';

//icons
import IconTime from 'components/iconTime/';
import IconFeeds from 'components/iconFeeds/';
import IconSounds from 'components/iconSounds/';
import IconSettings from 'components/iconSettings/';
import IconWeather from 'components/iconWeather/';

import Settings from 'containers/Settings';


class Navbar extends Component {

  constructor(props) {
    super(props);

    this.setPage = this.setPage.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);

  }

  toggleSettings(){
    const isOpen = this.props.navigation.settings === true ? false : true;
    this.props.actions.toggleSettings(isOpen);
  }

  setPage(page){
    this.props.actions.switchNavigation(page);
  }

  render() {

    return (
      <Header>
        <NavItem onClick={() => this.setPage("time")}>
          <IconTime></IconTime>
        </NavItem>
        <NavItem onClick={() => this.setPage("feeds")}>
          <IconFeeds></IconFeeds>
        </NavItem>
        <NavItem onClick={() => this.setPage("sounds")}>
          <IconSounds></IconSounds>
        </NavItem>
        <NavItem onClick={() => this.setPage("weather")}>
          <IconWeather></IconWeather>
        </NavItem>
        <NavSettings onClick={() => this.toggleSettings()}>
          <IconSettings></IconSettings>
        </NavSettings>
        <Settings {...this.props}></Settings>
      </Header>
    );
  }
}

export default Navbar;