import firebase from 'firebase'
var config = {
    apiKey: "AIzaSyCBxOwiLoRlYRA1FAOW7mShw_J1BDN02IA",
    authDomain: "dfreeme-7cc90.firebaseapp.com",
    databaseURL: "https://dfreeme-7cc90.firebaseio.com",
    projectId: "dfreeme-7cc90",
    storageBucket: "",
    messagingSenderId: "182029788429"
  };
firebase.initializeApp(config);
export default firebase;