const feeds = (state = {}, action) => {
 switch (action.type) {
  case 'SET_FEEDS':
   return { 
        feeds: [...action.payload]
  }
  default:
   return state
 }
}


export default feeds