import styled from 'styled-components';

//styles
export const Header = styled.header`
  position:fixed;
  top:0; left:0;
  overflow:auto;
  height:100vh;
  width:40px;
  padding:0px 10px 0px 10px;
  background-color:#131a21;
  overflow:visible;
  z-index:9;
  @media (max-width: 700px) {
    top:0px; left:0;
    width:100vw; height:40px;
    padding:0px;
  }
`;

export const NavItem = styled.div`
  height:20px; 
  padding:20px 10px;
  cursor:pointer;
  @media (max-width: 700px) {
    width:25%; float:left;
    padding:10px 0px;
  }
`;

export const NavSettings = styled.div`
  position:absolute;
  bottom:20px; left:0; right:0;
  margin:auto;
  height:20px; 
  padding:20px 10px;
  cursor:pointer;
  @media (max-width: 700px) {
    display:none;
  }
`;