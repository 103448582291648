import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as actionCreators from 'actions/actions';
import { connect } from 'react-redux';
import firebase from 'firebase.js';

//style containers
import { AppFrame } from './style.js';

//containers
import NavBar from 'containers/Navbar';
import Time from 'containers/Time';
import Feeds from 'containers/Feeds';
import SingleFeed from 'containers/Feed';
import Sounds from 'containers/Sounds';
import Weather from 'containers/Weather';



const mapStateToProps = state => ({
 ...state
});

const mapDispatchToProps = (dispatch) => {
  return {actions: bindActionCreators(actionCreators, dispatch)}
}



class App extends Component {

  constructor(props) {
    super(props);

    this.geoLoc = this.geoLoc.bind(this);

  }


  componentWillMount(){

    //get location
    let storedLocation;
    const locationRef = firebase.database().ref('location');
    locationRef.on('value', (snapshot) => {
        const fireLocation = snapshot.val();
        if(fireLocation){
          this.props.actions.setLocation(fireLocation);
          this.geoLoc(fireLocation);
        } else {
          // if there isn't a location set something as default
          // using Lambeau Field
          const defaultPos = {
            lat: "44.5013",
            long: "88.0622"
          }
          this.props.actions.setLocation(defaultPos);
          this.geoLoc(defaultPos);
        }
    })
  }



  geoLoc(storedLocation){
    console.log("geo Lockin");
    //get current location and check to see if it's different from the stored one
    navigator.geolocation.getCurrentPosition((position) => {
      const pos = {
        lat: position.coords.latitude,
        long: position.coords.longitude
      }
      // const storedLocation = this.props.location.position;
      if (storedLocation.lat !== pos.lat){
        console.log("resetting location");
        this.props.actions.setLocation(pos);
        const locationRef = firebase.database().ref('location');
        locationRef.set(
          pos
        );
      }
    });
  }

  render() {

    
    //navigation switcher
    const page = this.props.navigation.page;
    let show;

    if (page === "time") {
      show = <Time {...this.props}></Time>;

    } else if (page === "feeds"){
      const rssView = this.props.navigation.rssView
      if(rssView === "single"){
        show = <SingleFeed {...this.props}></SingleFeed>;
      } else {
        show = <Feeds {...this.props}></Feeds>;
      }
      
    } else if (page === "weather"){
      show = <Weather {...this.props}></Weather>;

    } else if (page === "sounds"){
      show = <Sounds {...this.props}></Sounds>;

    } else {
      //default to time view
      show = <Time {...this.props}></Time>;
    }

    window.scrollTo(0,0);

    return (
        <AppFrame>
          <NavBar {...this.props}></NavBar>
          {show}
        </AppFrame>
      );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);