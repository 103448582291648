import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/RootReducer';


// create an object for the default data
const defaultState = {
  navigation:{
    page: "",
    settings: "initial",
    rssView: "all",
    rssViewActive: 0
  },
  rss: {
    feeds:[]
  },
  location:{}
}


// const store = createStore(rootReducer, defaultState);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  defaultState,
  composeEnhancers(applyMiddleware(thunk))
);


export default store;