const location = (state = {}, action) => {
 switch (action.type) {
  case 'SET_LOCATION':
   return { 
        ...state, 
        position: action.payload
    }
  default:
   return state
 }
}

export default location
