import React, { Component } from 'react';

//style containers
import { 
  Container,
  Wrapper, 
  Clock, 
  DigitalClock,
  Day,
  Circ
} from './style.js';


class Time extends Component {


  constructor(props) {
    super(props);

    this.getDate = this.getDate.bind(this);
    this.setTime = this.setTime.bind(this);
    this.drawSeconds = this.drawSeconds.bind(this);
    this.drawMinutes = this.drawMinutes.bind(this);
    this.drawHours = this.drawHours.bind(this);

    this.state = {
      date: "",
      seconds: "",
      minutes: "",
      hours: ""
    }

  }

  componentWillMount(){
    this.getDate();
    this.setTime();
    this.setState({ 
      date: this.getDate(),
    });
  }
  componentDidMount(){
    this.interval = setInterval(() => {
      this.setTime();
      this.drawSeconds();
      this.drawMinutes();
      this.drawHours();
    }, 1000);
  }

   componentWillUnmount() {
    clearInterval(this.interval);
  }


  setTime(){
    const now = new Date();
    //hours
    let hours = now.getHours() % 12;
    //set hours to 12 if it comes back 0
    if (hours === 0) {hours += 12;}
    hours = hours > 9 ? hours : '0' + hours;
    //minutes
    let minutes = now.getMinutes();
    minutes = minutes > 9 ? minutes : '0' + minutes;
    //seconds
    let seconds = now.getSeconds();
    seconds = seconds > 9 ? seconds : '0' + seconds;
    this.setState({ 
      seconds,
      minutes,
      hours
    });
  }


  getDate(){
    const date = new Date();
    const months = [ "January", "February", "March", "April", "May", "June", 
               "July", "August", "September", "October", "November", "December" ];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const currentDate = month + "  " + (day<10 ? '0' : '') + day + ' ' + date.getFullYear();
    return currentDate;
  }

  drawSeconds(){
    let seconds = this.state.seconds;
    seconds = (2/60)*seconds;
    const canvas = this.refs.seconds;
    let context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.beginPath();
    context.arc(175, 175, 170, 0, seconds*Math.PI, false);
    context.lineWidth = 3;
    context.strokeStyle = '#1b516a';
    context.stroke();
  }

  drawMinutes(){
    let minutes = this.state.minutes;
    minutes = (2/60)*minutes;
    const canvas = this.refs.minutes;
    let context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.beginPath();
    context.arc(175, 175, 150, 0, minutes*Math.PI, false);
    context.lineWidth = 20;
    context.strokeStyle = '#1b516a';
    context.stroke();
  }

  drawHours(){
    let hours = this.state.hours;
    hours = (2/12)*hours;
    const canvas = this.refs.hours;
    let context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.beginPath();
    context.arc(175, 175, 110, 0, hours*Math.PI, false);
    context.lineWidth = 40;
    context.strokeStyle = '#1b516a';
    context.stroke();
  }


  render() {
    return (
      <Container>
        <Wrapper>
          <Clock>
            <Circ><canvas ref="seconds" width={350} height={350} /></Circ>
            <Circ><canvas ref="minutes" width={350} height={350} /></Circ>
            <Circ><canvas ref="hours" width={350} height={350} /></Circ>
          </Clock>
          <DigitalClock>{this.state.hours} {this.state.minutes} {this.state.seconds}</DigitalClock>
          <Day>{this.state.date}</Day>
        </Wrapper>
      </Container>
    );
  }
}

export default Time;