import { combineReducers } from 'redux';

import navigation from './Navigation';
import rss from './Feeds';
import location from './location';


export default combineReducers({
 navigation,
 rss,
 location
});