import styled from 'styled-components';



//styles
export const Container = styled.section`
  position:relative;
  overflow:auto;
  width:calc(100% - 150px); 
  height:auto;
  padding:75px 0px 75px 100px;
  @media (max-width: 900px) {
    padding:10px 0px 10px 75px;
    width:calc(100% - 100px);
  }
  @media (max-width: 700px) {
    width:100%;
    padding:50px 0px 100px 0px;
  }
`;

export const Header = styled.div`
  position:fixed;
  top:0; left:50px; right:0;
  width:calc(100% - 150px);
  margin:auto;
  padding:75px 0px 0px 0px;
  background-color:#1a232d;
  border-bottom:1px solid #1b516a;
  @media (max-width: 900px) {
    width:calc(100% - 100px);
    padding:30px 0px 0px 0px;
    left:55px;
  }
  @media (max-width: 700px) {
    width: calc(100% - 40px);
    margin: 30px 20px 0px 20px;
    left:0px;
  }
`;

export const Title = styled.h1`
  color:white;
  font:400 22px "Roboto";
  margin:0px;
  padding:0px 0px 10px 0px;
  width:calc(100% - 60px);
  float:left;
  @media (max-width: 900px) {
    font:400 14px "Roboto";
  }
`;


export const Link = styled.a`
  color:#a2a2a2;
  font:400 14px/18px "Roboto";
  text-decoration: none;
  padding:15px 0px 15px 0px;
  border-bottom:1px solid #131a21
  display:block;
  background-color:#1b516a1c;
  @media (max-width: 700px) {
      width: calc(100% - 40px);
      margin: 0px auto;
    }
  p{
    width:calc(100% - 45px);
    margin:0px 0px 0px 25px;
    @media (max-width: 900px) {
      font:400 12px "Roboto";
    }
    @media (max-width: 700px) {
      width: calc(100% - 40px);
      margin: 0px 20px 0px 20px;
    }
  }
  &:first-of-type{
    margin:57px 0px 0px 0px;
    @media (max-width: 900px) {
      margin:67px 0px 0px 0px;
    }
    @media (max-width: 700px) {
      width: calc(100% - 40px);
      margin: 60px 20px 0px 20px;
    }
  }
`;

export const Grid = styled.div`
  position:relative;
  float:right;
  margin:6px 0px 0px 0px;
  @media (max-width: 700px) {margin:0px 0px 0px 0px;}
`;