import React, { Component } from 'react';
import styled from 'styled-components';

export const Icon = styled.svg`
  height: 20px;
  fill: #1b516a;
  transition: all 0.2s ease-in-out;
  margin:0px auto;
  display:block;
  &:hover{
    fill:#CFE6CE;
    transition: all 0.2s ease-in-out;
  }
`;

class TimeIcon extends Component {

  render() {
    return (
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm57.1 350.1L224.9 294c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v137.7l63.5 46.2c5.4 3.9 6.5 11.4 2.6 16.8l-28.2 38.8c-3.9 5.3-11.4 6.5-16.8 2.6z"/></Icon>
    );
  }
}


export default TimeIcon;