import React, { Component } from 'react';

import firebase from 'firebase.js';

//style containers
import { 
  Move,
  Remove,
  Card
} from './style.js';

//icons
import IconRemove from 'components/iconRemove/';
import IconMove from 'components/iconMove/';


class EditFeeds extends Component {

  constructor(props) {
    super(props);

    this.removeFeed = this.removeFeed.bind(this);
    this.moveFeedUp = this.moveFeedUp.bind(this);
    this.moveFeedDown = this.moveFeedDown.bind(this);

  }

  removeFeed(){
    var index = this.props.i
    let feeds = this.props.rss.feeds
    feeds.splice(index, 1);
    this.props.actions.setFeeds(feeds);
    const feedsRef = firebase.database().ref('feeds');
    feedsRef.set(
      feeds
    );
  }

  moveFeedUp(){
    let feeds = this.props.rss.feeds
    const index = this.props.i
    const indexNew = index - 1;

    const element = feeds[index];
    feeds.splice(index, 1);
    feeds.splice(indexNew, 0, element);

    this.props.actions.setFeeds(feeds);
    const feedsRef = firebase.database().ref('feeds');
    feedsRef.set(
      feeds
    );
  }

  moveFeedDown(){
    let feeds = this.props.rss.feeds
    const index = this.props.i
    const indexNew = index + 1;

    const element = feeds[index];
    feeds.splice(index, 1);
    feeds.splice(indexNew, 0, element);

    this.props.actions.setFeeds(feeds);
    const feedsRef = firebase.database().ref('feeds');
    feedsRef.set(
      feeds
    );
  }



  render() {
    
    const checkPosition = this.props.i
    const totalFeeds = this.props.rss.feeds.length - 1
    let up;
    let down;
    if(checkPosition === 0){
      up = <span className="up disabled">
            <IconMove></IconMove>
          </span>
    } else {
      up = <span className="up" onClick={() => this.moveFeedUp()}>
            <IconMove></IconMove>
          </span>
    }
    if(totalFeeds === checkPosition){
      down = <span className="down disabled">
            <IconMove></IconMove>
          </span>
    } else {
      down = <span className="down" onClick={() => this.moveFeedDown()}>
            <IconMove></IconMove>
          </span>
    }

    return (
      <Card>
        <Remove onClick={() => this.removeFeed()}>
          <IconRemove></IconRemove>
        </Remove>
        <p>{this.props.val.title}</p>
        <Move>
          {up}
          {down}
        </Move>
      </Card>
    )
  }
}

export default EditFeeds;