import styled, { keyframes } from 'styled-components';

//keyframes
export const FadeIn = keyframes`
  from { opacity:0; transform: rotate(-70deg) translate(-15px, 0px); }
  to { opacity:1; transform: rotate(-90deg) translate(0px, 0px); }
`;

export const FadeInNoMove = keyframes`
  from { opacity:0; transform: translate(0px, 15px); }
  to { opacity:1; transform: translate(0px, 0px); }
`;


//styles
export const Container = styled.section`
  position:relative;
  overflow:auto;
  width:calc(100% - 60px); height:100%;
  max-width:750px;
  margin:0px auto;
  padding:0px 0px 0px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    width:100%;
    padding:0px 0px 0px 0px;
  }
`;

export const Wrapper = styled.div`
  position:relative;
  overflow:auto;
  width:100%; height:auto;
  padding:0px 30px;
`;

export const Clock = styled.div`
  position:relative;
  margin:0px auto;
  height:350px; width:350px;
  padding:10px;
  opacity:0;
  transform: rotate(-90deg) translate(-15px, 0px);
  animation: ${FadeIn} forwards 0.5s ease-out;
  animation-delay: 0.9s;
  @media (max-width: 500px) {
    height:250px; width:250px;
  }
`;

export const Circ = styled.div`
  position:absolute;
  left:0; right:0; top:0; bottom:0;
  margin:auto;
  height:350px; width:350px;
  @media (max-width: 500px) {
    height:250px; width:250px;
  }
  canvas{
    height:350px; width:350px;
    @media (max-width: 500px) {
      height:250px; width:250px;
    }
  }
`;

export const DigitalClock = styled.h1`
  position: relative;
  font: 100 150px 'Roboto', sans-serif;
  color: #CFE6CE;
  text-align: center;
  letter-spacing: 13px;
  margin:0px;
  width:100%;
  opacity:0;
  animation: ${FadeInNoMove} forwards 0.5s ease;
  animation-delay: 0.3s;
  @media (max-width: 950px) {font: 100 100px 'Roboto', sans-serif;}
  @media (max-width: 750px) {font: 100 70px 'Roboto', sans-serif;}
  @media (max-width: 500px) {
    font: 100 44px 'Roboto', sans-serif;
    letter-spacing: 8px;
  }
`;

export const Day = styled.p`
    font: 300 34px 'Roboto',sans-serif;
    color: #1b516a;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-align: center;
    margin:0px;
    width:100%; 
    opacity:0;
    animation: ${FadeInNoMove} forwards 0.5s ease-out;
    animation-delay: 0.3s;
    @media (max-width: 950px) {font: 300 24px 'Roboto',sans-serif;}
    @media (max-width: 750px) {font: 300 22px 'Roboto',sans-serif;}
    @media (max-width: 500px) {font: 300 20px 'Roboto',sans-serif;}
`;


