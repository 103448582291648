const navigation = (state = {}, action) => {
 switch (action.type) {
  case 'SWITCH_NAV':
   return { 
        ...state, 
        page: action.payload
    }
   case 'TOGGLE_SETTINGS':
   return { 
        ...state, 
        settings: action.payload
    }
    case 'SWITCH_FEED_VIEW':
    return { 
          ...state,
          rssView: action.payload
    }
    case 'SINGLE_FEED_VIEW_ACTIVE':
    return { 
          ...state,
          rssViewActive: action.payload
    }
  default:
   return state
 }
}

export default navigation
