import React, { Component } from 'react';



class Sounds extends Component {


  render() {
    return (
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>Sounds</h1>
      </div>
    );
  }
}

export default Sounds;