import { injectGlobal } from 'styled-components'

injectGlobal`

  @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400');

  body {
    padding: 0;
    margin: 0;
    background-color:#1a232d;
    font-family: "Roboto", sans-serif;
  }
`


// colors for now
// blue: #1a232d
// light blue: #1b516a
// dark blue: #131a21
// white: #CFE6CE


// var rssUrls = [
      //   'http://feeds.macrumors.com/MacRumors-Front',
      //   'http://www.bleedcubbieblue.com/rss/current',
      //   'http://www.acmepackingcompany.com/rss/current',
      //   "http://www.buckys5thquarter.com/rss/current",
      //   'http://deadspin.com/rss',
      //   'http://lifehacker.com/rss',
      //   'https://io9.gizmodo.com/rss',
      //   'http://www.theverge.com/rss/group/front-page/index.xml',
      //   'http://www.reddit.com/.rss',
      //   'https://www.reddit.com/r/phoenix/.rss',
      //   'http://news.ycombinator.com/rss',
      //   'http://www.dvorak.org/blog/feed/',
      //   'http://feeds.reuters.com/reuters/topNews',
      //   "http://alistapart.com/main/feed",
      //   "https://www.smashingmagazine.com/feed/",
      //   "http://feeds.feedburner.com/CssTricks",
      //   'http://www.chicagotribune.com/sports/baseball/cubs/rss2.0.xml',
      //   "http://www.rotoworld.com/rss/feed.aspx?sport=mlb&ftype=news&count=12&format=rss"
      // ];




      //https://query.yahooapis.com/v1/public/yql?q=select%20*%20from%20xml%20where%20url%20%3D%20'https://io9.gizmodo.com/rss'&format=json&env=store%3A%2F%2Fdatatables.org%2Falltableswithkeys