import React, { Component } from 'react';
import styled from 'styled-components';

export const Icon = styled.svg`
  height: 20px;
  fill: #1b516a;
  transition: all 0.2s ease-in-out;
  margin:0px auto;
  display:block;
  float:right;
  &:hover{
    fill:#CFE6CE;
    transition: all 0.2s ease-in-out;
  }
`;

class MoveIcon extends Component {

  render() {
    return (
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"/></Icon>
    );
  }
}


export default MoveIcon;