import React, { Component } from 'react';
import jsonp from 'jsonp';
import firebase from 'firebase.js';
import Skycons from 'react-skycons'


//style containers
import { 
  Container,
  Wrapper,
  Top,
  Mid,
  Bottom,
  Summary,
  Icon,
  SmallIcon,
  Temp,
  HighLow,
  Misc,
  TenDay,
  TenDayBlock
} from './style.js';


class Weather extends Component {

  constructor(props) {
    super(props);

    this.getWeather = this.getWeather.bind(this);
    this.refreshWeatherData = this.refreshWeatherData.bind(this);
    this.getIcon = this.getIcon.bind(this);
    this.getTime = this.getTime.bind(this);
    this.getDay = this.getDay.bind(this);
    this.extendedForcast = this.extendedForcast.bind(this);

    this.state = {
      temperature: "",
      icon: "CLEAR_DAY",
      precipProbability: "",
      windSpeed: "",
      windGust: "",
      summary: "",
      humidity: "",
      high: "",
      low: "",
      forcast: "",
      sunriseTime: "",
      sunsetTime: "",
      tenDayForcast: [],
      lastUpdate: ""
    }

  }
  componentWillMount(){
    const position = this.props.location.position;
    if(position){
      this.getWeather();
    }
  }


  extendedForcast(val, i){
    //display the next 5 days, skip today
    if(i < 6 && i > 0){
      const high = `${Math.round(val.temperatureHigh)}`;
      const low = `${Math.round(val.temperatureLow)}`;
      const icon = this.getIcon(val.icon)
      const day = this.getDay(val.time)
      return(
        <TenDayBlock key={i}>
          <p>{day}</p>
          <SmallIcon>
            <Skycons 
              color='#CFE6CE' 
              icon={icon}
              autoplay={true}
            />
          </SmallIcon>
          <p>{low}&deg;<br/>{high}&deg;</p>
        </TenDayBlock>
      )
    }
  }

  getIcon(icon){
    // const iconSet = ["CLEAR_DAY","CLEAR_NIGHT","PARTLY_CLOUDY_DAY","PARTLY_CLOUDY_NIGHT","CLOUDY","RAIN","SLEET","SNOW","WIND","FOG"];
    icon = icon.replace(/-/g,"_").toUpperCase()
    return icon.toString();
  }


  getDay(time){
    const t = new Date(time * 1000);
    const day = t.getDay();
    const names = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday',];
    return names[day];
  }



  getTime(time){
    const t = new Date(time * 1000);
    
    let hours = t.getHours() % 12;
    hours = hours > 9 ? hours : hours;

    let min = t.getMinutes();
    min = min > 9 ? min : '0' + min;

    const getAmPM = t.getHours();
    const ampm = getAmPM >= 12 ? 'pm' : 'am';

    const date = `${hours}:${min} ${ampm}`
    return date;
  }



  getWeather(){
    const weatherRef = firebase.database().ref('weather');
    weatherRef.on('value', (snapshot) => {
        const fireData = snapshot.val();
        let lastUpdate = 0;
        // get time of last DarkSky Call
        // fire firedata is empty force a call
        if(fireData){
          lastUpdate = fireData.lastUpdate;
        }
        //get the current time
        const currentTime = Date.now();
        // only fire DarkSky call if it's been more than an hour
        // 3600000 is 1 hours in milliseconds
        if( currentTime > (lastUpdate + 1800000) ){
          console.log("pinging darksky");
          this.refreshWeatherData();
        } else {
          console.log("getting weather from firebase");
          this.setState({...fireData});
        }
        return lastUpdate;
    }).bind(this);
  }


  refreshWeatherData(){

    const position = this.props.location.position;

    // use 4dd858d5702c5478d94e51cd7463aebc - dde.free@gmail.com
    // or 00ba7c40445fc9efa92b768be3871a5d - dan@badhawkworkshop.com
    const weatherUrl = `https://api.darksky.net/forecast/00ba7c40445fc9efa92b768be3871a5d/${position.lat},${position.long}`

    jsonp(weatherUrl, null, (err, response) => {
      
      if (err) {
        console.error(err.message);
      } else {
      
        const currently = response.currently;
        const daily = response.daily.data[0];
        const forcast = response.daily.data;
        console.log(forcast);
        //set current time because we made a call
        const currentTime = Date.now();

        this.setState({
          temperature: currently.temperature,
          icon: this.getIcon(currently.icon),
          windSpeed: currently.windSpeed,
          windGust: currently.windGust,
          summary: currently.summary,
          humidity: currently.humidity,
          high: daily.temperatureHigh,
          low: daily.temperatureLow,
          forcast: daily.summary,
          sunriseTime: this.getTime(daily.sunriseTime),
          sunsetTime: this.getTime(daily.sunsetTime),
          precipProbability: daily.precipProbability,
          lastUpdate: currentTime
        });
        this.setState({ tenDayForcast: [...this.state.tenDayForcast, ...forcast ] 
        });

        //add data to firebase
        const weatherRef = firebase.database().ref('weather');
        weatherRef.set(
          this.state
        );
      }

    }).bind(this);

  }




  render() {

    const position = this.props.location.position;
    const hasTemp = this.state.temperature;
    const tenDayForcast = this.state.tenDayForcast;

    if(!position || !hasTemp){
      return (
        <Container>
          
        </Container>
      );
    } else {
      return (
        <Container>
          <Wrapper>
            <Top>
              <Summary>{this.state.summary}</Summary>
              <Icon>
                <Skycons 
                  color='#CFE6CE' 
                  icon={this.state.icon}
                  autoplay={true}
                />
              </Icon>
            </Top>
            <Mid>
              <Temp>
                <p>{Math.round(this.state.temperature)}&deg;</p>
              </Temp>
              <HighLow>
                  <p>High: {Math.round(this.state.high)}&deg;</p>
                  <p>Low: {Math.round(this.state.low)}&deg;</p>
              </HighLow>
            </Mid>
            <Summary>{this.state.forcast}</Summary>
            <Bottom>
              <Misc>
                <p>Sunrise:
                  <span>{this.state.sunriseTime}</span>
                </p>
                <p>Sunset: 
                  <span>{this.state.sunsetTime}</span>
                </p>
                <p>Humidity: 
                  <span>{Math.round((this.state.humidity * 100))} %</span>
                </p>
                <p>Wind Speed: 
                  <span>{Math.round(this.state.windSpeed)} mph</span>
                </p>
                <p>Wind Gusts: 
                  <span>{Math.round(this.state.windGust)} mph</span>
                </p>
                <p>Precipitation %: 
                  <span>{this.state.precipProbability} %</span>
                </p>
              </Misc>
            </Bottom>
            <hr />
            <TenDay>
              { tenDayForcast.map( this.extendedForcast )}
            </TenDay>
          </Wrapper>
        </Container>
      );
    }


    
  }
}

export default Weather;