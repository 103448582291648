import React, { Component } from 'react';
import styled from 'styled-components';

export const Icon = styled.svg`
  height: 20px;
  fill: #1b516a;
  transition: all 0.2s ease-in-out;
  margin:0px auto;
  display:block;
  &:hover{
    fill:#CFE6CE;
    transition: all 0.2s ease-in-out;
  }
`;

class SoundsIcon extends Component {

  render() {
    return (
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M160 288h-16c-35.35 0-64 28.7-64 64.12v63.76c0 35.41 28.65 64.12 64 64.12h16c17.67 0 32-14.36 32-32.06V320.06c0-17.71-14.33-32.06-32-32.06zm208 0h-16c-17.67 0-32 14.35-32 32.06v127.88c0 17.7 14.33 32.06 32 32.06h16c35.35 0 64-28.71 64-64.12v-63.76c0-35.41-28.65-64.12-64-64.12zM256 32C112.91 32 4.57 151.13 0 288v112c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16V288c0-114.67 93.33-207.8 208-207.82 114.67.02 208 93.15 208 207.82v112c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16V288C507.43 151.13 399.09 32 256 32z"/></Icon>
    );
  }
}


export default SoundsIcon;