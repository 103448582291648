import styled from 'styled-components';


//styles
export const Move = styled.div`
    height:30px;
    width:30px;
    position:absolute;
    right:0px; top:8px;
    span{
      display:block;
      height:49%;
      width:30px;
      float:left;
      svg{
        margin:0px 0px 0px 0px;
        float:none;
        width:100%;
      }
    }
    .down{transform: rotate(180deg);}
    .disabled{
      opacity: 0.3;
      svg{&:hover{fill:#1b516a}}
    }
`;


export const Remove = styled.div`
    height:100%;
    width:20px;
    position:absolute;
    left:00px; top:13px;
`;

export const Card = styled.div`
    position:relative;
    border-bottom:1px solid #1b516a;
    width:100%;
    overflow:hidden;
    p{
      font-size:12px;
      line-height:10px;
      color:#CFE6CE;
      height:9px;
      width:80%;
      margin: 18px 0px 18px 50px;
    }
`;