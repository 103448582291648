import styled from 'styled-components';


//styles
export const Container = styled.section`
  position:relative;
  overflow:auto;
  width:calc(100% - 60px); height:100%;
  max-width:750px;
  margin:0px auto;
  padding:0px 0px 0px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    width:100%;
    padding:20px 0px 20px 0px;
  }
  hr{
    border:1px solid #131a21
  }
`;

export const Wrapper = styled.div`
  position:relative;
  overflow:auto;
  width:100%; height:auto;
`;


export const Top = styled.div`
  position:relative;
  overflow:auto;
  width:100%; height:auto;
`;

export const Mid = styled.div`
  position:relative;
  overflow:auto;
  width:auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Bottom = styled.div`
  position:relative;
  overflow:auto;
  width:auto;
  margin:60px 0px 30px 0px;
  p{
    color:#1b516a;
    text-align:center;
    font:100 18px/10px "Roboto";
  }
`;

export const Summary = styled.p`
  position:relative;
  color:#1b516a;
  text-align:center;
  font:100 34px/50px "Roboto";
  margin:0px;
  @media (max-width: 930px) {
    font:100 22px/34px "Roboto";
  }
`;

export const Icon = styled.div`
  position:relative;
  overflow:visible;
  height:100px; width:200px;
  margin:16px auto;
`;

export const SmallIcon = styled.div`
  position:relative;
  overflow:visible;
  height:25px; width:50px;
  margin:16px auto;
`;

export const Temp = styled.div`
  position:relative;
  overflow:auto;
  height:100px;
  p{
    float:left;
    color:#CFE6CE;
    margin:0px;
    font:100 70px/100px "Roboto";
  }
`;

export const HighLow = styled.div`
  position:relative;
  overflow:auto;
  height:70px;
  margin:-19px 0px 0px 30px;
  p{
    font:100 19px/52px "Roboto";
    width:100%; height:50%;
    color:#CFE6CE;
    margin:0px;
  }
`;

export const Misc = styled.div`
  position:relative;
  overflow:auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 930px) {
    flex-wrap: wrap;
  }
  p{
    font:100 18px/24px "Roboto";
    width:50%;
    color:#1b516a
    margin:0px;
    text-align:center;
    display:block;
    @media (max-width: 930px) {
      font:100 14px/17px "Roboto";
      margin:0px 0px 30px 0px;
      flex: 1 0 33%;
    }
  }
  span{
    display:block;
    color:#CFE6CE;
  }
`;


export const TenDay = styled.div`
  position:relative;
  overflow:auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 930px) {
    flex-wrap: wrap;
  }
`;

export const TenDayBlock = styled.div`
  position:relative;
  overflow:auto;
  width:50%;
  margin:20px 0px 0px 0px;
  @media (max-width: 930px) {
    flex: 1 0 33%;
    margin:20px 0px 0px 0px;
  }
  p{
    font:100 18px/24px "Roboto";
    color:#1b516a
    margin:0px;
    text-align:center;
    display:block;
    @media (max-width: 930px) {
      font:100 14px/17px "Roboto";
    }
  }
`;