import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import Store from './store';
import App from './containers/_App/';
import registerServiceWorker from './registerServiceWorker';

import { injectGlobal } from './globalStyle';


ReactDOM.render(
 <Provider store={Store}>
  <App />
 </Provider>,
 document.getElementById('root')
);
registerServiceWorker();
