import React, { Component } from 'react';

import Plus from "components/iconPlus"
import Refresh from "components/iconRefresh"

//style containers
import { 
  Container,
  Feed,
  Title,
  Link,
  List,
  PlusWrap,
  Header,
  Reload,
  PubDate
} from './style.js';



class Feeds extends Component {

  constructor(props) {
    super(props);

    this.displayFeed = this.displayFeed.bind(this);
    this.displayStory = this.displayStory.bind(this);
    this.displaySingleFeed = this.displaySingleFeed.bind(this);
    this.reloadFeeds = this.reloadFeeds.bind(this);

  }

  displaySingleFeed(i){
    this.props.actions.switchFeedView('single');
    this.props.actions.singleFeedViewActive(i);
  }

  reloadFeeds(){
    console.log('reload not working yet');
  }


  displayFeed(item, i){
    const newKey = item.title + i;
    return(
      <Feed key={newKey}>
        <Header>
          <Link href={item.link} target="_blank">
            <Title>{item.title}</Title>
            <p>{item.data}</p>
          </Link>
          <PlusWrap feedNum={i} onClick={() => this.displaySingleFeed(i)}>
            <Plus></Plus>
          </PlusWrap>
        </Header>
        <List>
          { item.items.map( this.displayStory ) }
        </List>
      </Feed>
    )
  }

  displayStory(val, i){

    //if link is in an object get the href
    let link = val.link;
    if (!link.length){
      link = link.href;
    }


    //fucking with times and shit
    
    const dateTime = new Date(val.isoDate); // ISO-8601 formatted date returned from server

    const day = dateTime.getDate();
    const month = dateTime.getMonth()+1;

    let hours = dateTime.getHours();
    if(hours < "10"){hours = "0" + hours}

    let minutes = dateTime.getMinutes();
    if(minutes < "10"){minutes = minutes + "0"}

    const ampm = hours >= 12 ? 'pm' : 'am';
    

    //only display 6 items
    if (i<6){
      return(
        <Link key={val.title} href={link} target="_blank"><p>{val.title}</p><span>{month}/{day} at {hours}:{minutes}</span></Link>
      )
    }
  }
  


  render() {

    const feeds = this.props.rss.feeds

    return (
      <Container>
        <Reload onClick={() => this.reloadFeeds()}><Refresh></Refresh></Reload>
        { feeds.map( this.displayFeed ) }
      </Container>
    );
    
  }
}

export default Feeds;