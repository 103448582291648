import React, { Component } from 'react';

//style containers
import { 
  Container,
  Link,
  Grid,
  Header,
  Title
} from './style.js';

//icons
import IconGrid from 'components/iconGrid/';



class SingleFeed extends Component {

  constructor(props) {
    super(props);

    this.displayStories = this.displayStories.bind(this);
    this.displayGridFeed = this.displayGridFeed.bind(this);
  }


  displayGridFeed(){
    this.props.actions.switchFeedView('all');
  }


  displayStories(val, i){
      return(
        <Link key={val.title} href={val.link} target="_blank"><p>{val.title}</p></Link>
      )
  }
  


  render() {

    const singleFeedView = this.props.navigation.rssViewActive
    const feed = this.props.rss.feeds[singleFeedView];

    return (
      <Container>
        <Header>
          <Title>{feed.title}</Title>
          <Grid onClick={() => this.displayGridFeed()}>
            <IconGrid></IconGrid>
          </Grid>
        </Header>
        { feed.items.map( this.displayStories ) }
      </Container>
    );
    
  }
}

export default SingleFeed;