// import axios from 'axios';

//switches the page you are viewing
export const SWITCH_NAVIGATION = 'SWITCH_NAVIGATION'
export const switchNavigation = (page) => dispatch => {
 dispatch({
  type: 'SWITCH_NAV',
  payload: page
 })
}
//toggle settings to open/close
export const TOGGLE_SETTINGS = 'TOGGLE_SETTINGS'
export const toggleSettings = (isOpen) => dispatch => {
 dispatch({
  type: 'TOGGLE_SETTINGS',
  payload: isOpen
 })
}
// switch feed view
export const SWITCH_FEED_VIEW = 'SWITCH_FEED_VIEW'
export const switchFeedView = (view) => dispatch => {
 dispatch({
  type: 'SWITCH_FEED_VIEW',
  payload: view
 })
}
export const SINGLE_FEED_VIEW_ACTIVE = 'SINGLE_FEED_VIEW_ACTIVE'
export const singleFeedViewActive = (feedNumber) => dispatch => {
 dispatch({
  type: 'SINGLE_FEED_VIEW_ACTIVE',
  payload: feedNumber
 })
}



//grab feeds
export const SET_FEEDS = 'SET_FEEDS'
export const setFeeds = (items) => dispatch => {
 dispatch({
  type: 'SET_FEEDS',
  payload: items
 })
}


//set current position
export const SET_LOCATION = 'SET_LOCATION'
export const setLocation = (pos) => dispatch => {
 dispatch({
  type: 'SET_LOCATION',
  payload: pos
 })
}

