import styled, { keyframes } from 'styled-components';



//keyframes
export const FadeIn = keyframes`
  0% {
    left:-100vw;
    opacity:0;
  }
  1%{
    left:0px;
    opacity:0;
  }
  100% {
    left:0px;
    opacity:1;
  }
`;

export const FadeOut = keyframes`
  0% {
    left:0px;
    opacity:1;
  }
  99%{
    left:0px;
    opacity:0;
  }
  100% {
    left:-100vw;
    opacity:0;
  }
`;


//styles

export const SettingsFrame = styled.section`
    position:absolute;
    top:0px;
    transform: translate(0,0);
    opacity:1;
    width:100vw; height:100vh;
    overflow:visible;
    padding:0px 0px 200px 0px;
    z-index:0;
    border-right:1px solid #1b516a;
    border-left:1px solid #1b516a;
    background-color:#131a21;
    animation: ${props => props.out ? FadeIn : FadeOut} forwards 0.3s ease-out;
`;

export const Header = styled.div`
    position:fixed;
    top:0; left:0;
    width:100vw; height:40px;
    background-color:#18242e;
    z-index:9;
    padding:0px 0px 20px 0px;
    border-bottom: 1px solid #131a21;
    display:block;
    h1{
      color:#CFE6CE;
      width:60%;
      float:left;
      padding:0px 0px 0px 25px;
    }
`;
export const Close = styled.div`
    margin:23px 25px 0px 0px;
    cursor:pointer;
`;

export const Feeds = styled.div`
    padding:60px 25px;
    width:600px;
    height:calc(100vh - 100px);
    overflow:scroll;
    border-right: 1px solid #18242e;
    h3{color:#1b516a;}
    input{
      background-color:#131a21;
      border:none;
      border-bottom:1px solid #CFE6CE;
      padding:10px 30px 10px 0px;
      margin:50px 0px 100px 0px;
      width:calc(80% - 38px);
      outline:none;
      color:#CFE6CE
    }
    button{
      border-radius:0px;
      border:1px solid #1b516a;
      background-color:#1b516a;
      color:#131a21;
      padding: 10px 15px;
      margin: 0px 0px 0px 17px;
      cursor:pointer;
      text-transform:uppercase;
      max-width:100px; width:calc(20%);
      &:hover{
        background-color:#CFE6CE;
      }
      &:focus{
        outline:none;
      }
    }
`;


