import styled from 'styled-components';



//styles
export const Container = styled.section`
  position:relative;
  overflow:auto;
  width:calc(100% - 150px);
  padding:75px 0px 75px 100px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    padding:10px 0px 10px 60px;
    width:calc(100% - 43px);
  }
  @media (max-width: 700px) {
    width:100%;
    padding:50px 0px 0px 0px;
  }
`;

export const Feed = styled.div`
  position:relative;
  float: left;
  width:calc(50% - 60px);
  overflow:hidden;
  margin:50px 20px;
  opacity: 1;
  background-color:#1b516a1c;
  @media (max-width: 900px) {
    width:calc(100% - 60px);
    margin:20px;
  }
  @media (max-width: 900px) {
    width:calc(100% - 40px);
    margin:20px;
  }
`;

export const Header = styled.div`
  position:relative;
  display:block;
  padding:0px 0px 7px 0px;
  border-bottom:1px solid #1b516a;
  background-color:#1a232d;
`;

export const Title = styled.h1`
  color:white;
  font:400 18px "Roboto";
  margin:0px 0px 0px 0px;
  width:calc(100% - 30px);
  @media (max-width: 900px) {
    font:400 14px "Roboto";
  }
`;

export const List = styled.div`
  display:block;
  padding:20px;
`;

export const Link = styled.a`
  color:#a2a2a2;
  font:400 14px/18px "Roboto";
  text-decoration: none;
  @media (max-width: 900px) {
    font:400 12px "Roboto";
  }
  span{
    color:#1b516a;
    font-size:12px;
    display:block;
    margin:-14px 0px 0px 0px;
  }
`;

export const PlusWrap = styled.div`
  position:absolute;
  right:0px; top:2px;
  @media (max-width: 700px) {
    top:0px;
    svg{height:16px;}
  }
`;

export const Reload = styled.div`
  position:absolute;
  right:0px; top:25px;
`;