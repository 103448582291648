import React, { Component } from 'react';
//import axios from 'axios';
import firebase from 'firebase.js';
import Parser from 'rss-parser';


import EditFeeds from "./EditFeeds";

//style containers
import { 
  SettingsFrame,
  Header,
  Close,
  Feeds
} from './style.js';

//icons
import IconClose from 'components/iconClose/';


class Settings extends Component {

  constructor(props) {
    super(props);

    this.toggleSettings = this.toggleSettings.bind(this);
    this.getRssData = this.getRssData.bind(this);
    this.getFirebaseData = this.getFirebaseData.bind(this);
    this.displayFeed = this.displayFeed.bind(this);
    this.addFeed = this.addFeed.bind(this);
    this.updateAddFeedValue = this.updateAddFeedValue.bind(this);
    this.getFeedType = this.getFeedType.bind(this);
    this.getAtomLink = this.getAtomLink.bind(this);

    this.state = {
      addFeed: "",
      hasLoaded: false
    }

  }

  toggleSettings(){
    const isOpen = this.props.navigation.settings === true ? false : true;
    this.props.actions.toggleSettings(isOpen);
  }

  updateAddFeedValue(event){
    this.setState({addFeed : event.target.value});
  }

  getFeedType(feed){
    if (feed.channel){
      return "atom";
    } else {
      return "rss";
    }
  }

  getAtomLink(link){
    if (link.length > 0){
      //fix for returning 'h'
      if(link[0] === 'h'){
        return link;
      } else {
        return link[1].href;
      }
    } else {
      return link.href
    }
  }



  addFeed(){
    const newFeed = this.state.addFeed
    const currentFeeds = this.props.rss.feeds;
    this.setState({addFeed : ""});
  
    let newItem;


    // let noCors = "https://gigantc-com.dan-91d.workers.dev/?url=" + encodeURIComponent(newFeed) + "?format=xml";
    // //if the url already contains a format don't add one
    // if(newFeed.includes("format")){
    //   noCors = 'https://gigantc-com.dan-91d.workers.dev/?url=' + encodeURIComponent(newFeed);
    // }

    let noCors = 'https://gigantc-com.dan-91d.workers.dev/?url=' + encodeURIComponent(newFeed);

    let parser = new Parser();
    parser.parseURL(noCors).then((response) => {

      // remove category, creator, author from items
      // doing this because many feeds have strange formats in here
      // and firebase doesn't like invalid keys
      const removeCats = response.items.map((val, i) => {
        delete val.categories; 
        delete val.creator; 
        delete val.author;
        delete val.content;
        return val;
      });
      //after the map is run put the new data into firebase
      Promise.all(removeCats).then((completed) => {
        
        newItem = {
          "title": response.title,
          "link": response.link,
          "url": newFeed,
          "items": removeCats
      }

      currentFeeds.push(newItem);
      this.props.actions.setFeeds(currentFeeds);

      const feedsRef = firebase.database().ref('feeds');
      feedsRef.set(
        currentFeeds
      );

      });


    }).catch(function(error) {
      console.log("error adding feed:" + newFeed);
      console.log(error);
    });

  }
  


  getRssData(rssData){

      const noCorsServers = [
        //"https://cors-bdhwk.herokuapp.com/",
        // "https://yacdn.org/proxy/",
        // "https://corsproxy.nodester.com/?src=",
        // "https://alloworigin.com/get?url=",
        // "https://thingproxy.freeboard.io/fetch/",
        //"https://www.whateverorigin.org/get?url=",
        //"https://cors-proxy.htmldriven.com/?url=",
        //"https://crossorigin.me/",
        //"https://api.allorigins.win/raw?url=",
        "https://gigantc-com.dan-91d.workers.dev/?url=",
      ]

      const results = rssData.map((val, i) => {

        //let item = noCorsServers[Math.floor(Math.random() * noCorsServers.length)];

        const feedURL = val.url;
        // let noCors = "https://api.allorigins.win/get?url=" + encodeURIComponent(feedURL) + "?format=xml";
        // //if the url already contains a format don't add one
        // if(feedURL.includes("format")){
        //   noCors = 'https://api.allorigins.win/get?url=' + encodeURIComponent(feedURL);
        // }

        // let noCors = item + val.url + "?format=xml";
        // //if the url already contains a format don't add one
        // if(val.url.includes("format")){
        //   noCors = item + val.url;
        // }

        let noCors = "https://gigantc-com.dan-91d.workers.dev/?url=" + encodeURIComponent(feedURL);
        

        //let parser = new Parser();
        let parser = new Parser({
          headers: {
            "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "GET,HEAD,POST,OPTIONS",
            // "Access-Control-Max-Age": "86400",
            // 'Content-Type': 'application/json',
            'mode':'no-cors'
          },
        });

        if(!this.state.hasLoaded){
          // we're wrapping this in an if statement to make sure we don't keep hitting cors-anywhere over and over again.
          // trying to be nice and also not get blocked
          parser.parseURL(noCors).then((response) => {
            console.log(response);
            //if everything comes through ok
            if(response !== undefined){
              // console.log('loaded:');
              //console.log(response);
              // console.log('-------------------------');
              this.setState({hasLoaded: true});
              // remove category, creator, author from items
              // doing this because many feeds have strange formats in here
              // and firebase doesn't like invalid keys
              const removeCats = response.items.map((val, i) => {
                delete val.categories; 
                delete val.creator; 
                delete val.author;
                delete val.content;
                return val;
              });
              //after the map is run put the new data onto the page
              Promise.all(removeCats).then((completed) => {
                rssData[i].items = removeCats;
                this.props.actions.setFeeds(rssData);
              });

            }

          }).catch(function(error) {
            console.log("error with feed:" + val.url);
            console.log(error);
          });
        } //end hasLoadedCheck
        return val;
      })

      //lets wait about 30 seconds and then push this up to firebase
      setTimeout(function(){ 
          // console.log('Feed Sync to Base:');
          // console.log(results);
          const feedsRef = firebase.database().ref('feeds');
          feedsRef.set(
            results
          );
      }, 30000);
  }


  getFirebaseData(){
    const feedsRef = firebase.database().ref('feeds');
    feedsRef.on('value', (snapshot) => {
        const fireData = snapshot.val();
        this.getRssData(fireData);
    })
  }

  displayFeed(val, i){
    const newKey = val.title + i;
    return(
      <EditFeeds {...this.props} val={val} i={i} key={i}></EditFeeds>
    )
  }

  componentWillMount(){
    this.getFirebaseData();
  }


  render() {

      const isOpen = this.props.navigation.settings;
      const feeds = this.props.rss.feeds
      //if it's the first time let's not render the settings menu
      if (isOpen === "initial"){
        return(null)
      } else {
        return (
          <SettingsFrame out={isOpen}>
            <Header>
              <h1>SETTINGS</h1>
              <Close onClick={() => this.toggleSettings()}>
                <IconClose></IconClose>
              </Close>
            </Header>
            <Feeds>
              <h3>Feeds</h3>
              { feeds.map( this.displayFeed )}
              <p>{this.state.feedError}</p>
              <input value={this.state.addFeed} onChange={this.updateAddFeedValue} placeholder="rss feed url"/>
              <button onClick={() => this.addFeed()}>Add Feed</button>
            </Feeds>
          </SettingsFrame>
        )
      }
  }
}

export default Settings;